<template>
  <div class="basicParamsPage">
    <Listcontainer
      :searchText.sync="searchText"
      :isAdd="true"
      :addText="`添加参数组`"
      :placeholder="`参数组名称/参数组代码`"
      :pagination="pagination"
      @add="add"
      @multipleDel="multipleDel"
      @fetch="loadData"
    >
      <template v-slot:grid
        ><Grid
          :Gridtype="Gridtype"
          @intoGrid="view"
          :GridData="data"
          :isExport="true"
          :isUpdate="true"
          :isDel="true"
          @add="add"
          @deleteRow="deleteRow"
          @editRow="edit"
          @export="exportgroup"
      /></template>
      <template v-slot:defalut> defalut-slot </template>
      <template v-slot:table>
        <Basetable :multi="false" :data="data" :columns="columns" :operations="operations" :multipleSelection.sync="multipleSelection" />
        <!-- <el-table :data="data" style="width: 100%">
          <el-table-column type="index" label="序号" width="80" align="center"> </el-table-column>
          <el-table-column prop="name" label="参数组名称" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="view(scope.row)">{{ scope.row.name }}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="code" label="参数组代码" align="center"> </el-table-column>
          <el-table-column prop="nestNumber" label="被引用数量" align="center"> </el-table-column>
          <el-table-column label="操作" align="center" width="180">
            <template slot-scope="scope"> -->
        <!-- <el-button type="text" icon="el-icon-share" @click="exportgroup(scope.row)" title="导出"></el-button> -->
        <!-- <el-button type="text" icon="el-icon-upload2" @click="exportgroup(scope.row)" title="导出"></el-button>
              <el-button type="text" icon="el-icon-edit" @click="edit(scope.row)" title="编辑"></el-button>
              <el-button type="text" icon="el-icon-delete" @click="deleteRow(scope.row)" title="删除"></el-button>
            </template>
          </el-table-column>
        </el-table> -->
      </template>
    </Listcontainer>
  </div>
</template>
<script>
import Basetable from '@/components/table/base-table/base-table';
import Listcontainer from '@/components/list-container/list-container';
import Grid from '@/components/grid/index.vue';
import { TABLE } from '/app.config';

const { edit, del, fileExport } = TABLE.icons;
export default {
  components: {
    Listcontainer,
    Grid,
    Basetable
  },
  data() {
    return {
      Gridtype: 3,
      searchText: '',
      data: [],
      pagination: {
        total: 100,
        pageSize: 20,
        currentPage: 1
      },
      columns: [
        {
          prop: 'name',
          label: '参数名称',
          href: true,
          handle: this.view
        },
        {
          prop: 'code',
          label: '参数代码'
        },
        {
          prop: 'nestNumber',
          label: '被引用数量',
          align: 'center'
        }
      ],
      operations: {
        width: 200,
        handles: [
          {
            icon: fileExport,
            name: '导出',
            handle: this.exportgroup
          },
          {
            icon: edit,
            name: '编辑',
            handle: this.edit
          },
          {
            icon: del,
            name: '删除',
            handle: this.deleteRow
          }
        ]
      }
    };
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData(param = {}) {
      this.Gridtype = 2;
      const { currentPage, pageSize } = this.pagination;
      const data = {
        currentPage: param.currentPage || currentPage,
        currentPageSize: param.currentPageSize || pageSize,
        keyword: this.searchText,
        groupType: 2
      };
      const res = await this.$apis.basicParamsGroup.list(data);
      this.data = res.items;
      this.pagination = {
        total: res.total,
        pageSize: res.currentPageSize,
        currentPage: res.currentPage
      };
    },
    add() {
      this.$router.push({ path: '/nestParams/edit', query: { data: null } });
    },
    edit(item) {
      this.$router.push({ path: '/nestParams/edit', query: { data: item } });
    },
    async view(item) {
      this.$router.push({ path: '/nestParams/info', query: { data: item } });
    },
    deleteRow(item) {
      this.$confirm('确认要删除已选参数组吗?', '删除参数组', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fail } = await this.$apis.NestGroup.del({ groupId: item.groupId });
        this.loadData();
        if (fail) {
          // eslint-disable-next-line no-useless-return
          return false;
        }
      });
    },
    multipleDel() {
      let groupIds = [];
      this.multipleSelection.forEach(item => groupIds.push(item.groupId));
      this.$apis.basicParamsGroup.del({ groupIds: groupIds.join(',') });
      this.loadData();
    },
    async exportgroup(data) {
      const res = await this.$apis.NestGroup.exportValidNestParam({ groupId: data.groupId });
      if (res) {
        this.$apis.NestGroup.exportNestParam({ groupId: data.groupId });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.basicParamsPage {
  height: 100%;
}
/deep/ .el-table thead th.el-table__cell,
.el-table__header-wrapper {
  background-color: #f3f3f3 !important;
}
/deep/.el-table {
  height: 100%;
  .el-table__body-wrapper {
    height: calc(100% - 100px);
  }
  .el-table__row {
    height: 44px;
    td {
      padding: 0;
    }
  }
}
</style>
